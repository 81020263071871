/*
 * Variable height units with fallback to vh
 */
:root {
  --dvh-full: 100vh;
  --svh-full: 100vh;
  --lvh-full: 100vh;
}

@supports (height: 100dvh) {
  :root {
    --dvh-full: 100dvh;
  }
}

@supports (height: 100svh) {
  :root {
    --svh-full: 100svh;
  }
}

@supports (height: 100lvh) {
  :root {
    --lvh-full: 100lvh;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
