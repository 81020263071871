body {
  @apply font-sans text-february antialiased;
}

input {
  @apply placeholder-february-muted;
}

a {
  @apply cursor-pointer text-february underline transition-colors ease-in hover:text-spring;
}

svg .color {
  @apply text-honey;
}
